import stipsApiService from "@/services/modules/stips";
import type { IStip } from "@/models/applications";
import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta
} from "@/models/common";
import type { IRootState } from "@/models/state";
import type { IStipsState } from "@/models/stips";
import { produce } from "immer";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import uniqBy from "lodash/uniqBy";

interface StipParams {
  ids: number[];
  product_types: string[];
  workflow_stage_ids: string[];
  [key: string]: unknown;
}

const getDefaultState = (): IStipsState => {
  return {
    data: [],
    links: {} as IResponseLinks,
    meta: {} as IResponseMeta,
    loading: false,
    stipToDelete: null
  };
};

const state: IStipsState = getDefaultState();

const mutations: MutationTree<IStipsState> = {
  setStips(state, stipsPayload: IPaginatedResponse<IStip>) {
    state.data = stipsPayload.data;
    state.links = stipsPayload.links;
    state.meta = stipsPayload.meta;
  },
  unsetStips(state) {
    state.data = [];
    state.links = {} as IResponseLinks;
    state.meta = {} as IResponseMeta;
  },
  setUpdatedStip(state, updatedStip: IStip) {
    state.data = produce(state.data, (draft) => {
      const index = draft.findIndex((stip) => stip.id === updatedStip.id);
      draft[index] = updatedStip;
    });
  },
  setCreatedStip(state, createdStip: IStip) {
    state.data = [createdStip, ...state.data];
  },
  unsetDeletedStip(state, deletedStipId: number) {
    state.data = state.data.filter((stip) => stip.id !== deletedStipId);
  }
};

const actions: ActionTree<IStipsState, IRootState> = {
  async getStips(
    { commit, state },
    { params, append = false }: { params: StipParams; append: boolean }
  ) {
    state.loading = true;
    try {
      const response = await stipsApiService.getStips(params);
      const newStipsCollection = append
        ? {
            data: uniqBy(state.data.concat(response.data), "id"),
            links: response.links,
            meta: response.meta
          }
        : response;
      commit("setStips", newStipsCollection);
      return response.data ?? [];
    } finally {
      state.loading = false;
    }
  },
  async createStip({ commit }, payload: Partial<IStip>) {
    const createdStip = await stipsApiService.createStip(payload);
    if (createdStip) {
      commit("setCreatedStip", createdStip);
    }
    return createdStip;
  },
  async updateStip(
    { commit },
    {
      id,
      payload
    }: {
      id: number;
      payload: Partial<IStip>;
    }
  ) {
    const updatedStip = await stipsApiService.updateStip(id, payload);
    commit("setUpdatedStip", updatedStip);
  },

  async updateStipDescription(
    { commit },
    { id, description }: { id: number; description: string }
  ) {
    const updatedStip = await stipsApiService.updateStip(id, {
      description
    });
    commit("setUpdatedStip", updatedStip);
  },

  async deleteStip({ commit }, stipId: number) {
    const { status } = await stipsApiService.deleteStip(stipId);
    if (status === 204) {
      commit("unsetDeletedStip", stipId);
    }
    return status === 204;
  },

  async updateStipFileType(
    { commit },
    { id, file_type }: { id: number; file_type: string }
  ) {
    const updatedStip = await stipsApiService.updateStip(id, {
      file_type
    });
    commit("setUpdatedStip", updatedStip);
  }
};

const getters: GetterTree<IStipsState, IRootState> = {
  stipsData(state) {
    return state;
  },
  stipsByIdMap(state) {
    return Object.fromEntries(state.data.map((stip) => [stip.id, stip]));
  },
  isLoading(state) {
    return state.loading;
  }
};

export const stips = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
