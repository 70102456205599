import { format, isToday } from "date-fns";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { useI18n } from "vue-i18n";
import { FULL_MONTHS, ROLES_MAP, STATUS_DESCRIPTIONS } from "./constants";
import type { IAddress, HTTPError, Nullish } from "@/models/common";
import type { Role } from "@/models/options";
import type { EmailAddress } from "@/models/IndividualProfile";
import type { PhoneNumber } from "@/models/workflows";
import type { CSSProperties } from "vue";
import isEmpty from "lodash/isEmpty";
import {
  endOfMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  sub,
  startOfQuarter,
  endOfQuarter,
  endOfDay
} from "date-fns";
import { DynamicDate } from "@/enums/datetime";
import { isNullish, getColumnValueColor } from "@/helpers/common";
import isValid from "date-fns/isValid";
import { FieldType } from "@/enums/offers";

export function formatDate(value: string | Date, placeholder = "-"): string {
  if (!value) {
    return placeholder;
  }
  if (
    formatDateCustom(value, "dd.MM.yyyy", true) ===
    formatDateCustom(new Date(), "dd.MM.yyyy", true)
  ) {
    return formatDateCustom(value, "hh:mm a", true);
  }

  if (new Date(value).getFullYear() !== new Date().getFullYear()) {
    return formatDateCustom(value, "MMM dd, yyyy, hh:mm a", true);
  }

  return formatDateCustom(value, "MMM dd, hh:mm a", true);
}

export const formatToLocaleDate = (value: string): string => {
  if (!value) {
    return "-";
  }

  const dateTime = new Date(value);

  const options: Record<string, string> = {
    hour: "2-digit",
    minute: "2-digit"
  };

  return `${new Date(dateTime.getTime()).toLocaleDateString()},
  ${new Date(dateTime.getTime()).toLocaleTimeString([], options)}`;
};

export function formatDateCustom(
  value: string | number | Date | null | undefined,
  pattern = "MM/dd/yyyy",
  perTimeZone = false
): string {
  if (
    !value ||
    (typeof value === "string" && ["undefined", "null"].includes(value)) ||
    !isValid(new Date(value))
  ) {
    return "-";
  }
  const utcDate = new Date(value);

  return format(
    new Date(
      utcDate.getTime() +
        (perTimeZone ? 0 : utcDate.getTimezoneOffset() * 60000)
    ),
    pattern
  );
}

export const formatMonth = (month: number) => {
  const { t } = useI18n();
  if (month > 1) {
    return month + ` ${t("COMMON.MONTHS")}`;
  }
  return month + ` ${t("COMMON.MONTH")}`;
};

export const formatTimeElapsed = (time: number) => {
  if (time < 0) {
    return "0m";
  }
  const days = Math.floor(time / 86400);
  const hours = Math.floor((time - days * 86400) / 3600);
  const minutes = Math.floor((time - (days * 86400 + hours * 3600)) / 60);
  if (days === 0) {
    if (hours === 0) {
      return `${minutes}m`;
    }
    return `${hours}h ${minutes}m`;
  }
  return `${days}d ${hours}h ${minutes}m`;
};

export function formatStringValue(
  value: string | number | null | undefined,
  placeholder = "-"
): string {
  if (
    !value ||
    (typeof value === "string" && ["undefined", "null"].includes(value))
  ) {
    return placeholder;
  }
  return String(value);
}

export const validateUUID = (
  uuid: string | null | undefined
): uuid is string => {
  return (
    typeof uuid === "string" &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      uuid
    )
  );
};

export function truncateUUID(value: string | null | undefined): string {
  if (!value) {
    return "";
  }
  if (!validateUUID(value)) {
    return value;
  }
  return value?.split("-")[0];
}

export function formatMoney(
  value: number | string | null | undefined,
  minimumFractionDigits = 0,
  currency = "USD",
  locale = "en-US",
  currencyDisplay: "symbol" | "narrowSymbol" | "code" | "name" = "symbol"
) {
  if (Number.isNaN(parseFloat(`${value}`))) {
    return "";
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
    currencyDisplay
  });
  return formatter.format(parseFloat(`${value}`));
}

export function formatPercentage(
  value: number | string | null | undefined,
  decimals = 0
): string | null {
  if (
    !value ||
    [0, "0"].includes(value) ||
    Number.isNaN(parseFloat(`${value}`))
  ) {
    return "0%";
  }

  const numberValue = parseFloat(`${value}`);
  if (Math.round(numberValue) == value) {
    return `${value}%`;
  }
  return `${numberValue.toFixed(decimals)}%`;
}

export function formatMoneyRange(value: string | null | undefined): string {
  if (!value) {
    return "";
  }
  const rangeArray = value.split(" - ");
  const minValue = formatMoney(rangeArray[0]);
  const maxValue = formatMoney(rangeArray[1]);
  return `${minValue} - ${maxValue}`;
}

export function formatMoneyShort(value: number): string {
  if (!value) return "-";
  let formatted = `${value}`;

  if (value >= 1000000) {
    formatted = (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (value >= 1000) {
    formatted = (value / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return `$${formatted}`;
}

export const formatFileSize = (
  sizeInKilobytes: number | string | null | undefined
): { unit: string; size: number } =>
  !sizeInKilobytes
    ? { unit: "KB", size: 0 }
    : Number(sizeInKilobytes) > 1024
      ? {
          unit: "MB",
          size: Number(
            `${Math.round(parseFloat(`${sizeInKilobytes}`) / 1024)}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            )
          )
        }
      : {
          unit: "KB",
          size: Math.round(parseFloat(`${sizeInKilobytes}`))
        };

export const getDocumentFileType = (
  originalName: string | null | undefined
): string => {
  const type =
    !originalName || !originalName.includes(".")
      ? ""
      : originalName.split(".").slice(-1)[0].toLowerCase();
  if (type === "docx") {
    return "doc";
  }
  if (type === "xlsx") {
    return "xls";
  }
  return type;
};

export const formatDocumentListFileSize = (originalSize: number): string => {
  const { t } = useI18n();
  const { size, unit } = formatFileSize(originalSize / 1024); // bytes to kbytes
  return t(`DEALS.DOCUMENTS.UPLOAD_FILE_SIZE_${unit}`, { size });
};

export const formatUserFullName = <
  U extends { first_name: string; last_name: string }
>(
  user?: Partial<U> | null
): string => {
  if (!user) {
    return "";
  }
  const firstName = user.first_name || "";
  const lastName = user.last_name || "";
  return [firstName, lastName].filter(Boolean).join(" ");
};

export const sortProperties = (
  obj: Record<string, string> | string[],
  sortIndex = 1,
  prefix = " "
): Record<string, string> => {
  const sortedArray = Object.entries(obj).sort(function (a, b) {
    const x = a[sortIndex].toLowerCase();
    const y = b[sortIndex].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });

  // It will prefix key with " " because some browsers will automatically sort object if key is a number
  const newObject: Record<string, string> = {};
  sortedArray.forEach((old) => (newObject[prefix + old[0]] = old[1]));

  return newObject;
};

export const wordsFirstLetterToUpper = (
  sentence: string | null | undefined,
  separator = " "
): string =>
  !sentence
    ? ""
    : sentence
        .split(separator)
        .filter(Boolean)
        .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`)
        .join(" ");

export const fullPathToObject = (
  string: string | null | undefined
): Record<string, string> =>
  !string?.includes("?")
    ? {}
    : string
        .slice(string.indexOf("?") + 1, string.length)
        .split("&")
        .reduce((acc, curr) => {
          const equalsIndex = curr.indexOf("=");
          const key = curr.slice(0, equalsIndex);
          const value = curr.slice(equalsIndex + 1, curr.length);
          return {
            ...acc,
            [key]: value
          };
        }, {});

export const formatDocumentFileType = (
  type: string | undefined | null
): string => {
  if (!type) {
    return "";
  }
  const nameArray = type.split("_");
  const typeNumber = parseInt(nameArray[nameArray.length - 1]);
  if (isNaN(typeNumber)) {
    return wordsFirstLetterToUpper(type.replace(/_/g, " "));
  } else {
    return wordsFirstLetterToUpper(
      type
        .split("_")
        .join(" ")
        .slice(0, type.length - 2)
    );
  }
};

export const objectValuesEmpty = <T extends Record<string, unknown>>(
  object: T
): boolean => {
  return Object.values(object).every((x) => x === null || x === "");
};

export const formatAddress = (
  address: Partial<IAddress> | string[]
): string => {
  if (Array.isArray(address)) {
    return address.join(", ");
  }
  return [
    address.address_line,
    address.address_line2,
    address.city,
    address.state,
    address.zip,
    address.country
  ]
    .filter((elem) => !!elem?.length)
    .join(", ");
};

/**
 * Collects only active filter object values
 *
 * @param filtersObj Object that contains either primitive or non-primitive values
 * @returns {object} Sanitized Filter Object
 */
export const getActiveFilters = <T extends Record<string, unknown>>(
  filtersObj: T
): Record<string, string | number | boolean> => {
  return Object.entries(filtersObj).reduce((sanitized, entry) => {
    const [key, value] = entry;
    if (
      value === null ||
      (Array.isArray(value) && !value.length) ||
      (typeof value === "string" && value.trim() === "")
    ) {
      return sanitized;
    }
    return {
      ...sanitized,
      [key]: value
    };
  }, {});
};

/**
 * Does the passed value look like a money string like '$1,234.00' or '$34' ?
 *
 * @param value The value to test
 * @returns {boolean}
 */
export const looksLikeCurrency = (value: string): boolean => {
  // reject strings like '$1.23 is the total cost'
  if (/\s/.test(value.trim())) {
    return false;
  }

  // if it doesn't start with '$', we assume it is not currency
  if (
    !value.startsWith("$") &&
    !value.startsWith("-$") &&
    !value.startsWith("US$") &&
    !value.startsWith("-US$") &&
    !value.startsWith("CA$") &&
    !value.startsWith("-CA$") &&
    !value.endsWith("USD")
  ) {
    return false;
  }

  // remove all non-number and decimal values from the string
  const sanitizedValue = value.replace(/[^\d.]/g, "");

  if (sanitizedValue.length === 0) {
    return false;
  }

  return !isNaN(Number(sanitizedValue));
};

/**
 * Take a string like '$42,438.21' and convert it to a new string representing
 * the same amount, but which can safely be cast to a number, ie. '42438.21'.
 *
 * @throws Will throw an error when the passed string does not look like currency
 * @param {string} value
 * @returns {number}
 */
export const convertCurrencyStringToNumber = (value: string): string => {
  if (!looksLikeCurrency(value)) {
    throw new Error(`The value [${value}] is not a currency string.`);
  }

  return value.replace(/[^\d.]/g, "");
};

/**
 * Create a new version of the passed object, converting any values that look like
 * currency strings into strings the API will treat as valid numeric values.
 *
 * This only affects on string values. Nested objects are not supported.
 *
 * @param {object} payload
 * @returns {object} The sanitized payload
 */
export const formatPayloadWithCurrency = (
  payload: Record<string, unknown>
): Record<string, string> =>
  Object.entries(payload).reduce((acc, [key, value]) => {
    let returnVal = value;
    if (typeof value !== "number" && looksLikeCurrency(String(value))) {
      returnVal = convertCurrencyStringToNumber(String(value));
    }

    return { ...acc, [key]: returnVal };
  }, {});

export const truncateID = (token: string, last = 8): string => {
  return token.substring(0, last);
};

export const getValueForDateField = (
  originalVal: string,
  withTime: boolean
): string => {
  if (originalVal?.length < 10) {
    return "";
  }
  const dateTimeFormat = withTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd";
  let valueCopy = `${originalVal}`;

  if (withTime && valueCopy.length === 10) {
    valueCopy += " 00:00";
  }

  const value = format(new Date(valueCopy), dateTimeFormat, {
    locale: enUS
  });

  return withTime ? value.replace(" ", "T") : value;
};

export const isFormFilledAndHasNoErrors = <T extends Record<string, unknown>>(
  errors: T,
  values: Record<string, string | []>
): boolean => {
  if (Object.keys(errors)?.length) {
    return false;
  }
  const valuesArray = Object.values(values);
  return !!valuesArray?.length && valuesArray.every((val) => val?.length);
};

export const formatBooleanToReadableValue = (
  value: boolean | string | number | null | undefined
): string => {
  return value ? "Yes" : "No";
};

export const addSuffix = (
  val: number | string,
  suffix: string | number
): string => {
  const pluralChar = val == 1 ? "" : "s";

  return `${val} ${suffix}${pluralChar}`;
};

export const sortOn = <Type>(
  prop: keyof Type,
  list: string[]
): ((a: Type, b: Type) => number) => {
  const order = list.reduce(
    (obj, key, idx) => ({ ...obj, [key]: idx + 1 }),
    {}
  );

  const getVal = (item: Type): number =>
    order[item[prop] as unknown as keyof typeof order] || Infinity;

  return (a, b) => getVal(a) - getVal(b);
};

export const formatNumberWithCommas = (value: number, options = {}): string => {
  if (Number.isNaN(parseInt(`${value}`))) {
    return "";
  }
  const formatter = new Intl.NumberFormat("en-US", options);

  return formatter.format(value);
};

// should be removed because this can be done easier
export const fixTo = (value: number | string, decimals = 2): number | null => {
  if (typeof value !== "number" && !value) {
    return null;
  }
  if (value === 0 || Math.round(parseInt(`${value}`, 10)) === value) {
    return value;
  }
  const parsedValue = typeof value === "string" ? parseInt(value, 10) : value;
  return parseFloat(parsedValue.toFixed(decimals));
};

export const getFunderEmails = (
  emailsObject: Record<string, string[]> | null | undefined
): string[] => {
  if (!emailsObject) {
    return [];
  }
  const all = Object.values(emailsObject).flat();
  return Array.from(new Set(all)).filter(Boolean);
};

export const concatenateErrorMessages = (
  errorMessages: string[] | null | undefined
): string => {
  if (!errorMessages?.length) {
    return "";
  }
  let message: string;

  const suffix = `${errorMessages.length > 1 ? "are" : "is"} required`;
  if (errorMessages.length === 1) {
    message = `${errorMessages[0]} ${suffix}`;
  } else {
    const concatenated = errorMessages.reduce(
      (acc, curr, i, array) =>
        acc + (i === 0 ? "" : i < array.length - 1 ? ", " : " and ") + curr,
      ""
    );
    message = `${concatenated} ${suffix}`;
  }
  return message[0].toUpperCase() + message.slice(1);
};

export const makeRandomString = (length = 10): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const filterObjectByValue = <TObject extends Record<string, unknown>>(
  obj: TObject,
  condition: (value: TObject[keyof TObject]) => boolean
): Partial<TObject> => {
  const entries = Object.entries(obj);
  return Object.fromEntries(
    entries.filter((entry) => condition(entry[1] as TObject[keyof TObject]))
  ) as Partial<TObject>;
};

export const filterNotNullableValues = <T extends Record<string, unknown>>(
  obj: T,
  notNullableKeys: Array<string>
): T => {
  const entries = Object.entries(obj);
  return Object.fromEntries(
    entries.filter(([key, value]) => value || !notNullableKeys.includes(key))
  ) as T;
};

export const forceNullForEmptyStrings = <T>(obj: T | null | undefined): T => {
  if (!obj) {
    return {} as T;
  }
  const entries = Object.entries(obj);
  return Object.fromEntries(
    entries.map(([key, value]) => [key, value !== "" ? value : null])
  ) as T;
};
export const getServiceFileName = (
  clientName: string,
  businessName: string,
  serviceCategory: string,
  service: string
): string => {
  return `${clientName} - ${businessName} - ${serviceCategory} - ${service} - ${formatDateCustom(
    new Date(),
    "MM.dd.yyyy"
  )}`;
};

export const safeParseToFloat = (
  value: string | number | null | undefined
): number => {
  if (!value) {
    return 0;
  }
  return parseFloat(`${value}` || "0");
};

export const moneyToTwoDecimal = (money: number | string): string =>
  formatMoney(money, 2);

export const percentToTwoDecimal = (value: number): string | null =>
  formatPercentage(value, 2);

export const formatPhoneNumber = (value: string | null | undefined): string => {
  if (!value) {
    return "-";
  }
  const cleanedPhone = value.replace(/[^0-9]+/g, "");
  const corePhoneNumber = cleanedPhone.substr(cleanedPhone?.length - 10);
  const formatedCorePhoneNumber = `(${corePhoneNumber.substr(
    0,
    3
  )}) ${corePhoneNumber.substr(3, 3)} - ${corePhoneNumber.substr(6)}`;
  if (cleanedPhone.length > 10) {
    return `+${cleanedPhone?.replace(
      corePhoneNumber,
      ""
    )} ${formatedCorePhoneNumber}`;
  }
  return formatedCorePhoneNumber;
};

export const removeCurrencyPrefix = (
  value: string | number | null | undefined
): number => {
  if (!value || ["undefined", "null"].includes(`${value}`)) {
    return 0;
  }
  const stringValue = `${value}`;

  if (!stringValue.includes("$")) {
    return parseFloat(stringValue);
  }

  const dollarIndex = stringValue.lastIndexOf("$");
  const parsed = parseFloat(stringValue.substring(dollarIndex + 1));
  return isNaN(parsed) ? 0 : parsed;
};

export const formatString = (
  string: string | null | undefined,
  ...replacers: (string | number)[]
): string => {
  if (!string) {
    return "";
  }
  let formattedString = `${string}`;

  if (!formattedString.includes("%s")) {
    return formattedString;
  }

  let index = 0;

  while (formattedString.includes("%s")) {
    if (!replacers[index]) {
      break;
    }
    formattedString = formattedString.replace("%s", `${replacers[index]}`);
    index++;
  }

  return formattedString;
};

export const getDealStatusName = (
  status: number | null
): { status_description: string } => {
  return {
    status_description:
      STATUS_DESCRIPTIONS[status as keyof typeof STATUS_DESCRIPTIONS] ||
      STATUS_DESCRIPTIONS[0]
  };
};

export const logWarning = (...textParams: string[]): void => {
  // must be used inside a vue template script body since it's a hook
  const { t } = useI18n();
  //eslint-disable-next-line
  console.log(
    `%c${t("COMMON.CONSOLE_WARNING")}!`,
    "color:red; background: yellow; font-size: 14px",
    ...textParams.map((text, i) => (i === 0 ? `\n\n${text}` : `\n${text}`))
  );
};

export const toHex = (val: string | number): string => {
  const hex = Number(val).toString(16);
  return hex.length == 1 ? `0${hex}` : hex;
};

export const rgbToHex = (rgb: string): string => {
  const [r, g, b] = rgb.replace(/ /g, "").split(",");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const hexToRgb = (hex: string): string | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result?.length !== 4) {
    return null;
  }
  const red = parseInt(result[1], 16);
  const green = parseInt(result[2], 16);
  const blue = parseInt(result[3], 16);
  return `${red}, ${green}, ${blue}`;
};

export const getDateFromMonth = (month: string) => {
  const monthToString = String(month);

  const monthValue =
    Object.entries(FULL_MONTHS).find(
      (keyValue) => keyValue[1].toLowerCase() === monthToString.toLowerCase()
    )?.[0] || monthToString;

  if (!Object.keys(FULL_MONTHS).includes(monthValue as string)) {
    return "";
  }

  return `${new Date().getFullYear()}-${(monthValue || "1").padStart(
    2,
    "0"
  )}-01`;
};

export const stringIsJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getUserRoles = (roles: Role[]) => {
  return roles.map((role) => ROLES_MAP[role] || role) || [];
};

export const camelCaseToWords = (str: string) => {
  if (!str) {
    return "-";
  }
  // updated from here (https://stackoverflow.com/a/35953318) for more complex cases
  const result = str
    .replace(/(_)+/g, " ")
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2")
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2")
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2")
    // Note: the next regex includes a special case to exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2")
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2")
    // Note: the next two regexes use {2,} instead of + to add space on phrases like Room26A and 26ABCs but not on phrases like R2D2 and C3PO"
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2")
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2")
    .trim();

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getInitials = (username: string) => {
  const words = username.split(/[ -]/);
  let initials = "";
  words.forEach((word) => {
    initials += word.charAt(0);
  });
  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, "");
  }
  initials = initials.substr(0, 3).toUpperCase();
  return initials;
};

export const parseMiddeskDateToEcma = (date: string) =>
  // (this is specific to middesk data return)
  // reformat the given date string to match the ECMAscript Date Time String Format
  // this is so we know the browser implements the parsing of the date string
  // (parsing of this string as-is failed in Firefox v105 and Safari v15.6 on Mac OS)
  date.split(" ").join("T").split(".")[0] + "Z";

export const formatWFUpdateError = (e: HTTPError) => {
  const updatedErrorEntries = Object.entries(e.response.data.errors).map(
    (entry) => {
      return [entry[0].substring(entry[0].lastIndexOf(".") + 1), entry[1]];
    }
  );
  e.response.data.errors = Object.fromEntries(updatedErrorEntries);
  return e;
};

export function commaSeparatedStringFormatter<T>(
  items: Array<T> | null,
  accessor: keyof T,
  itemsPerColumn = 4
) {
  if (!items?.length) {
    return "-";
  }

  let names = items
    .slice(0, itemsPerColumn)
    .map((item) => item[accessor])
    .join(", ");

  if (items.length > itemsPerColumn) {
    names += "...";
  }

  return names;
}

export function truncateWithEllipsis(value: string, maxLength = 40) {
  return value.length > maxLength
    ? `${value.substring(0, maxLength)}...`
    : value;
}

export const getFormatedMultipleFields = <
  T extends { [K in "email_address" | "phone_number"]?: string } & (
    | EmailAddress
    | PhoneNumber
  ),
  V extends Record<string, unknown>
>(
  existingItems: T[],
  values: V,
  type: "email_address" | "phone_number",
  primaryId: string | number
): Partial<T>[] =>
  Object.entries(values).reduce((result: Partial<T>[], [key, value]) => {
    if (!key.includes(`${type}_`) || isEmpty(value)) {
      return result;
    }

    const item = {} as T;

    const id = key.replace(`${type}_`, "");
    const exist = existingItems.find((i) => i.id === Number(id));

    if (exist) {
      item.id = Number(id);
    }

    item[type] = String(value);
    item.is_primary = id === primaryId;

    result.push(item);
    return result;
  }, []);

export const formatTimeAgo = (date: string, showTimeIfToday = false) => {
  if (!date) {
    return "";
  }

  const jsDate = typeof date === "string" ? parseISO(date) : date;

  if (showTimeIfToday && isToday(jsDate)) {
    return format(jsDate, "p");
  }

  return formatDistanceToNow(jsDate, {
    addSuffix: true,
    locale: enUS
  });
};

export const phoneToE164 = (phone: string | undefined, placeholder = "") => {
  if (!phone) {
    return placeholder;
  }
  const phoneRegex = /\+?\d+/g;
  let filteredPhoneNumber = phone.match(phoneRegex)?.join("");

  if (!filteredPhoneNumber?.startsWith("+1")) {
    filteredPhoneNumber = `+1${filteredPhoneNumber}`;
  }

  return filteredPhoneNumber;
};

export const getShortcutDate = (shortcut: string, date?: Date | string) => {
  const currentDate = date ? new Date(date) : new Date();
  const dateTypeToValue: Record<DynamicDate, () => [Date, Date]> = {
    [DynamicDate.TODAY]: () => [currentDate, endOfDay(currentDate)],
    [DynamicDate.YESTERDAY]: () => {
      const yesterday = sub(currentDate, { days: 1 });
      return [yesterday, endOfDay(yesterday)];
    },
    [DynamicDate.THIS_WEEK]: () => [
      startOfWeek(currentDate, { weekStartsOn: 1 }),
      endOfWeek(currentDate, { weekStartsOn: 1 })
    ],
    [DynamicDate.LAST_WEEK]: () => {
      const todayDateLastWeek = sub(currentDate, {
        days: 7
      });
      const firstDay = startOfWeek(todayDateLastWeek, { weekStartsOn: 1 });
      const lastDay = endOfWeek(todayDateLastWeek, { weekStartsOn: 1 });
      return [firstDay, lastDay];
    },
    [DynamicDate.THIS_MONTH]: () => [
      startOfMonth(currentDate),
      endOfMonth(currentDate)
    ],
    [DynamicDate.LAST_MONTH]: () => {
      const lastMonth = sub(currentDate, { months: 1 });
      const firstDay = startOfMonth(lastMonth);
      const lastDay = endOfMonth(lastMonth);
      return [firstDay, lastDay];
    },
    [DynamicDate.LAST_THIRTY_DAYS]: () => {
      const firstDay = sub(currentDate, { days: 29 });
      const lastDay = currentDate;
      return [firstDay, lastDay];
    },
    [DynamicDate.THIS_QUARTER]: () => {
      const firstDay = startOfQuarter(currentDate);
      const lastDay = endOfQuarter(currentDate);
      return [firstDay, lastDay];
    },
    [DynamicDate.LAST_SIX_MONTHS]: () => {
      const firstDay = sub(currentDate, { months: 6 });
      const lastDay = currentDate;
      return [firstDay, lastDay];
    },
    [DynamicDate.THIS_YEAR]: () => {
      const firstDay = new Date(currentDate.getFullYear(), 0, 1);
      const lastDay = new Date(currentDate.getFullYear(), 11, 31);
      return [firstDay, lastDay];
    },
    [DynamicDate.LAST_YEAR]: () => {
      const firstDay = new Date(currentDate.getFullYear() - 1, 0, 1);
      const lastDay = new Date(currentDate.getFullYear() - 1, 11, 31);
      return [firstDay, lastDay];
    },
    [DynamicDate.LAST_365_DAYS]: () => {
      const firstDay = sub(currentDate, { days: 364 });
      const lastDay = currentDate;
      return [firstDay, lastDay];
    }
  };

  return dateTypeToValue[shortcut as DynamicDate]?.() ?? ["", ""];
};

export const getNumberOrdinal = (number: number) => {
  const suffix = ["th", "st", "nd", "rd"];
  const v = number % 100;
  return number + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
};

export const convertUTCDateToLocalDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

/**
 * Returns style based on the input value being < 0.
 * With optional color for positive values
 *
 * @param {string|number|null|undefined} value - value to be checked
 * @param {string} [stylePositive] - optional color for positive values
 * @returns {CSSProperties | undefined} - CSS Style  with color || undefined
 */
export const getValueStyle = (
  value: string | string[] | number | null | undefined,
  stylePositive?: string
): CSSProperties | undefined => {
  if (isNullish(value)) {
    return;
  }
  return {
    color: getColumnValueColor(
      Array.isArray(value) ? value.join(", ") : String(value),
      stylePositive
    )
  };
};

export const prepareUrl = (url = "") => {
  if (!url.startsWith("http")) {
    return `https://${url}`;
  }
  return url;
};

export const transformStringToArray = (
  value: string | null | undefined,
  separator = ","
) => {
  if (!value) {
    return [];
  }
  return value.split(separator).map((item) => item.trim());
};

export const maskBankAccountNumber = (
  accNum: string,
  viewStart: number,
  viewEnd?: number
) => {
  const visiblePart = viewEnd
    ? accNum.slice(viewStart, viewEnd)
    : accNum.slice(viewStart);
  return `**** ${visiblePart}`;
};

export const capitalizeStringFirstLetters = (value: string | Nullish) => {
  if (typeof value !== "string") {
    return "";
  }
  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const centsToDollars = (cents: number | null, showZero = false) => {
  if (!cents || (cents === 0 && !showZero)) {
    return "-";
  }
  return formatMoney(cents / 100);
};

export const offerFieldFormatter: Record<
  FieldType,
  (
    value: string | number,
    options?: string[] | Record<string | number, string>
  ) => string | number
> = {
  [FieldType.INTEGER]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.FLOAT]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.PERCENT]: (value: string | number) => {
    return formatPercentage(value, 3) ?? "-";
  },
  [FieldType.STRING]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.URL]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.CURRENCY]: (value: string | number) => {
    return formatMoney(value, 3);
  },
  [FieldType.OPTIONS]: (
    value: string | number,
    options?: string[] | Record<string | number, string>
  ) => {
    if (value === null) {
      return "-";
    }
    if (Array.isArray(value)) {
      return (options as string[])?.[value as number] ?? "-";
    }
    return (options as Record<string | number, string>)?.[value] ?? "-";
  },
  [FieldType.DATE]: (value: string | number) => {
    return formatDateCustom(value, "MMM dd, yyyy");
  },
  [FieldType.TEXTAREA]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.DEFAULT]: (value: string | number) => {
    return value ?? "-";
  }
};
