import type { Ref } from "vue";
import type { IApplication, IStip } from "@/models/applications";
import {
  WORKFLOW_MAPPINGS,
  WORKFLOW_FUNDING,
  OBFUSCATED_PREFIX,
  WORKFLOW_CATEGORY_CREDIT_REVIEW,
  WORKFLOW_CATEGORY_RENTAL,
  WORKFLOW_CATEGORY_DATA_COLLECTION,
  WORKFLOW_CATEGORY_INTERNAL_FUNDING,
  WORKFLOW_CATEGORY_MARKETPLACE
} from "@/helpers/constants";
import type { IAddress, Identifiable, Nullish } from "@/models/common";
import { formatDistance } from "date-fns";
import { format } from "date-fns";
import { useI18n } from "vue-i18n";
import { usePlaidDataBase } from "@/hooks/plaid";
import {
  getDropdownOptions,
  getDropdownOptionsByKey,
  looksLikeNumber
} from "@/helpers/common";
import { MathOperation } from "@/enums/customAttributes";
import { looksLikeCurrency } from "@/helpers/formatting";
import type { DeleteRecord } from "@/models/workflows";
import type { AxiosRequestConfig, Method, AxiosResponse } from "axios";
import { axiosClient } from "@/services/client";
import { formatString } from "@/helpers/formatting";
import type { IOffer } from "@/models/funders";
import { OfferSaveMode } from "@/enums/offers";
import { useDealFiles } from "@/hooks/deals";
import fundersApiService from "@/services/modules/funders";

export const getDealWorkflowType = (deal: IApplication): string => {
  const workflowKey = Object.keys(WORKFLOW_MAPPINGS).find(
    (workflow) => deal[workflow as keyof IApplication]
  );

  return (
    WORKFLOW_MAPPINGS[workflowKey as keyof typeof WORKFLOW_MAPPINGS] ??
    WORKFLOW_FUNDING
  );
};

export const isGivenCountryCanada = (
  country: string | null | undefined
): boolean => {
  return !!country && ["canada", "ca", "can"].includes(country.toLowerCase());
};

export const isObfuscated = (val: string): boolean => {
  return val.includes(OBFUSCATED_PREFIX);
};

export const hasBankConnection = (deal: IApplication) => {
  const { plaidData } = usePlaidDataBase();
  const { files } = useDealFiles(deal.id);

  const hasBankStatements = files.value.some((file) =>
    file.file_type.includes("bank_statements_")
  );

  return (
    (!plaidData.value?.plaid?.created_at && hasBankStatements) ||
    !!plaidData.value?.bank_accounts?.length
  );
};

//Filters out empty addresses and sets country to US if not set
export const filterOutEmptyAddresses = (
  addresses: (IAddress | DeleteRecord)[]
): (IAddress | DeleteRecord)[] | [] =>
  addresses?.reduce((addresses: (IAddress | DeleteRecord)[], address) => {
    if ("_delete" in address) {
      addresses.push(address);
      return addresses;
    }
    if (
      !!address.address_line ||
      !!address.city ||
      !!address.country ||
      !!address.state ||
      !!address.zip ||
      !!address.is_primary
    ) {
      addresses.push(
        address?.country ? address : { ...address, country: "US" }
      );
    }
    return addresses;
  }, []);

export const formatUTCRelativeTime = (
  receivedDate: string,
  distance = false
) => {
  const { t } = useI18n();
  if (!receivedDate) {
    return;
  }
  try {
    const [date, time] = receivedDate.split(" ");
    const [year, month, day] = date.split("-");
    const [hours, minutes, seconds] = time.split(":");
    const dateToFormat = new Date(
      Date.UTC(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hours),
        Number(minutes),
        Number(seconds)
      )
    );
    return distance
      ? t("COMMON.LAUNCHED", {
          date: formatDistance(dateToFormat, new Date(), {
            addSuffix: true
          })
        })
      : format(dateToFormat, "MMM do, yyyy h:mm aa");
  } catch (e) {
    return "";
  }
};

export const getDealCategory = (deal: IApplication | Nullish) => {
  if (deal?.is_business_credit) {
    return WORKFLOW_CATEGORY_CREDIT_REVIEW;
  }
  if (deal?.is_equipment_rental) {
    return WORKFLOW_CATEGORY_RENTAL;
  }
  if (deal?.is_data_capture) {
    return WORKFLOW_CATEGORY_DATA_COLLECTION;
  }
  if (deal?.is_client_funded) {
    return WORKFLOW_CATEGORY_INTERNAL_FUNDING;
  }
  return WORKFLOW_CATEGORY_MARKETPLACE;
};

export const getMoneyClass = (balance: number | string) => {
  return Number(balance) < 0 ? "text-error" : "";
};

export const hasAddressWithoutCountry = (addresses: IAddress[]) =>
  addresses.some(({ country }) => !country);

export const transformCurrencyToNumber = (value: string) =>
  looksLikeCurrency(value) ? value.replace(/[^\d.]/g, "") : value;

export const getCalculatedResult = (
  firstValue: number | string | Nullish,
  secondValue: number | string | Nullish,
  operation: MathOperation
): string => {
  const [firstValueNumber, secondValueNumber] = [firstValue, secondValue].map(
    (value) => {
      if (!value) {
        return 0;
      }
      const transformedValue = transformCurrencyToNumber(String(value));
      return looksLikeNumber(transformedValue) ? Number(transformedValue) : 0;
    }
  );

  switch (operation) {
    case MathOperation.add:
      return (firstValueNumber + secondValueNumber).toFixed(2);
    case MathOperation.subtract:
      return (firstValueNumber - secondValueNumber).toFixed(2);
    case MathOperation.multiply:
      return (firstValueNumber * secondValueNumber).toFixed(2);
    case MathOperation.divide:
      return secondValueNumber
        ? (firstValueNumber / secondValueNumber).toFixed(2)
        : "0";
    default:
      return "0";
  }
};

export interface RequestDetails {
  httpMethod: Method;
  baseUrl: string;
  urlModifiers?: string[];
  requestConfig?: AxiosRequestConfig;
}

let currentApplicationId: string | undefined;

const abortControllers: Record<
  string,
  InstanceType<typeof AbortController> | null
> = {};

const cancelPreviousRequest = (requestType: string): void => {
  const source = abortControllers[requestType];
  if (source) {
    source?.abort();
    abortControllers[requestType] = null;
  }
};

const cancelRequestsForPreviousApplication = () => {
  if (!currentApplicationId) {
    return;
  }
  const oldApplicationRequests = Object.keys(abortControllers).filter((key) =>
    key.startsWith(currentApplicationId as string)
  );
  oldApplicationRequests.forEach((key) => {
    cancelPreviousRequest(key);
  });
};

export const sendRequestWithCancellation = async <T>(
  request: RequestDetails,
  applicationId?: string
): Promise<T> => {
  const requestType = applicationId
    ? `${applicationId}:${request.httpMethod}:${request.baseUrl}`
    : `${request.httpMethod}:${request.baseUrl}`;

  cancelPreviousRequest(requestType);

  abortControllers[requestType] = new AbortController();

  const response: AxiosResponse<T> = await axiosClient.request({
    method: request.httpMethod,
    url: formatString(request.baseUrl, ...(request.urlModifiers || [])),
    ...(request.requestConfig ?? {}),
    signal: abortControllers[requestType]?.signal
  });

  abortControllers[requestType] = null;
  return response.data;
};

export const setNewApplicationIdAndCancelPreviousRequests = (
  applicationId: string
): void => {
  if (currentApplicationId && currentApplicationId !== applicationId) {
    cancelRequestsForPreviousApplication();
  }
  currentApplicationId = applicationId;
};

export const generateDuplicateOfferPayload = (originalOffer: IOffer) => {
  const payload = {
    offer: {
      ...originalOffer.details.offer,
      offer_template_id: originalOffer.details.offer_template.id,
      stips: originalOffer.stips?.map((stip) => {
        return {
          id: (stip as IStip & { stip_id?: number }).stip_id || stip.id,
          description: stip.description,
          file_type: stip.file_type
        };
      }),
      application_id: originalOffer.application_id,
      placement_id: originalOffer.placement_id
    },
    mode: OfferSaveMode.SAVE
  };
  return payload;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const createOptionsUpdatedHandler =
  <
    TData extends Record<string, any>,
    TOptionsRef extends Ref<Record<string, TData[keyof TData]>>
  >(
    optionsRef: TOptionsRef,
    key?: keyof TData
  ) =>
  (data: TData[], type: "search" | "loadmore") => {
    let newOptions = {};

    if (key) {
      newOptions = getDropdownOptionsByKey(data, key);
    } else {
      newOptions = getDropdownOptions(data as unknown as Identifiable[]);
    }

    if (type === "search") {
      optionsRef.value = newOptions;
      return;
    }

    optionsRef.value = {
      ...optionsRef.value,
      ...newOptions
    };
  };
/* eslint-enable @typescript-eslint/no-explicit-any */

export const getFundersDropdownOptions = async (params: {
  page: number;
  search: string;
}) => {
  const response = await fundersApiService.getFunders(params);
  return {
    ...response,
    data: response.data.map(
      /** The options factory works on a "name" property */
      ({ full_name: name, ...rest }) => ({ ...rest, name })
    )
  };
};
