import { OfferProductType } from "@/enums/offers";
import IconCurrency from "@/components/icons/offerTemplate/IconCurrency.vue";
import IconPercent from "@/components/icons/offerTemplate/IconPercent.vue";
import IconText from "@/components/icons/offerTemplate/IconText.vue";
import IconNumber from "@/components/icons/offerTemplate/IconNumber.vue";
import IconDate from "@/components/icons/offerTemplate/IconDate.vue";
import IconOption from "@/components/icons/offerTemplate/IconOption.vue";
import IconUrl from "@/components/icons/offerTemplate/IconUrl.vue";
import type { Component } from "vue";
import IconFloat from "@/components/icons/offerTemplate/IconFloat.vue";
import IconTextArea from "@/components/icons/offerTemplate/IconTextArea.vue";

export const TERM_LENGTH_FIELD = "term_length";
export const FUNDING_COMMISSION_FIELD = "funding_commission";
export const TOTAL_INITIAL_PAYMENT_FIELD = "total_initial_payment";

export const OFFER_SCHEMA_VERSION = "1.1.0" as const;

const OFFER_FIELDS = {
  term_length: TERM_LENGTH_FIELD,
  funding_commission: FUNDING_COMMISSION_FIELD,
  total_initial_payment: TOTAL_INITIAL_PAYMENT_FIELD
};

export const PRODUCT_TYPE_OPTIONS = {
  [OfferProductType.ARLOC]: "Arloc",
  [OfferProductType.EQUIPMENT_FINANCIN]: "Equipment Financing",
  [OfferProductType.LINE_OF_CREDIT]: "Line of Credit",
  [OfferProductType.RECEIVABLES_PURCHASE]: "Receivables Purchase",
  [OfferProductType.TERM_LOAN]: "Term Loan"
} as const;

export const offerFieldIcons: Record<string, Component> = {
  percentage: IconPercent,
  currency: IconCurrency,
  string: IconText,
  integer: IconNumber,
  date: IconDate,
  options: IconOption,
  url: IconUrl,
  float: IconFloat,
  text: IconTextArea
} as const;

export const INTERNAL_FUNDER_ID_PLACEHOLDER = "[internal]";

export default OFFER_FIELDS;
