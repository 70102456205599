import type { UnderwritingStatusType } from "@/models/underwriting";
import { useI18n } from "vue-i18n";

export const INITIATOR_TYPE_DATA_ORCHESTRATION = "data orchestration";
export const INITIATOR_TYPE_USER = "user";

export const UNDERWRITING_STATUS_PASS = "pass";
export const UNDERWRITING_STATUS_FAIL = "fail";
export const UNDERWRITING_STATUS_UNDETERMINED = "undetermined";
export const UNDERWRITING_STATUS_MANUAL_REVIEW = "manual review";
export const UNDERWRITING_STATUS_PAUSED = "paused";

export const UNDERWRITING_SERVICE_OUTCOME_ICONS: Record<
  UnderwritingStatusType,
  string
> = {
  [UNDERWRITING_STATUS_PASS]: "success-big-green",
  [UNDERWRITING_STATUS_FAIL]: "fail-big-red",
  [UNDERWRITING_STATUS_UNDETERMINED]: "fail-big-red",
  [UNDERWRITING_STATUS_MANUAL_REVIEW]: "warning-small"
};

// This map exists so to provide the correct mapping between the underwriting tabs and the service name returned from DO logs,
// this approach has become flawed since the attributes from each service could map to more than one tab
// This will be left as is to act as a fallback, but it should be updated in case of new tabs

export const UNDERWRITING_TAB_TO_SECTION_MAP = (): Record<string, string> => {
  const { t } = useI18n();
  return {
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.KYB")]: "KYB",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.ACCOUNTING_DATA")]: "Accounting Data",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.KYC")]: "KYC",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.MATCH")]: "Match",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.FRAUD")]: "Fraud",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.CFA_TITLE")]: "Cash Flow Analysis",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.BUSINESS_CREDIT")]: "Business Credit",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.PERSONAL_CREDIT")]: "Personal Credit",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.BANKRUPTCIES")]: "Bankruptcies",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.JUDGEMENTS")]: "Judgments",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.UCCS")]: "UCCs",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.LIENS")]: "Liens",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.SOCIAL_SCORING.SUBTITLE")]:
      "Social Credit Score",
    [t("DEALS.DEAL_PROGRESS.UNDERWRITING.DOC_VERIFICATION")]:
      "Document Verification"
  };
};

const CFA_SERVICES = ["ocrolus_cfa", "ocrolus_suspicious_activity"] as const;

const CFA_ATTRIBUTES = [
  "ocrolus_has_bank_data",
  "ocrolus_cfa_unexpected_error",
  "ocrolus_cfa_average_alternative_lender_payments",
  "ocrolus_cfa_average_alternative_lender_debits",
  "ocrolus_cfa_average_alternative_lender_debits_count",
  "ocrolus_cfa_average_alternative_lender_credits",
  "ocrolus_cfa_average_alternative_lender_credits_count",
  "ocrolus_cfa_average_daily_balance",
  "ocrolus_cfa_average_daily_flow",
  "ocrolus_cfa_average_deposit",
  "ocrolus_cfa_average_estimated_daily_revenue",
  "ocrolus_cfa_average_estimated_revenue",
  "ocrolus_cfa_average_monthly_deposit_count",
  "ocrolus_cfa_average_monthly_withdrawal",
  "ocrolus_cfa_average_net_cash_flow_sum",
  "ocrolus_cfa_average_nsf_count",
  "ocrolus_cfa_average_transactions_count",
  "ocrolus_cfa_average_transaction_size",
  "ocrolus_cfa_average_negative_balance_count",
  "ocrolus_cfa_average_weekday_negative_balance_count",
  "ocrolus_suspicious_activity_suspicious_activity"
] as const;

const BANK_DATA_SERVICES = ["plaid"] as const;

const BANK_DATA_ATTRIBUTES = [
  "plaid_connection",
  "plaid_beginning_balance",
  "plaid_deposits_count",
  "plaid_deposits_total",
  "plaid_ending_balance",
  "plaid_low",
  "plaid_negative_days",
  "plaid_withdrawals_count",
  "plaid_withdrawals_total"
] as const;

const BANKRUPTCIES_SERVICES = [
  "experian_bankruptcies",
  "lexis_nexis_bankruptcy_search"
] as const;

const BANKRUPTCIES_ATTRIBUTES = [
  "experian_bankruptcies",
  "lexis_nexis_bankruptcy_search_number_of_bankruptcy_filed"
] as const;

const MATCH_SERVICES = [
  "sentilink_ssn_completion",
  "sentilink_dob_completion",
  "experian_business_match",
  "dnb_bm_l1",
  "clear_id_confirm_business",
  "clear_id_confirm_person"
] as const;

const MATCH_ATTRIBUTES = [
  "sentilink_ssn_completion_confidence_score_SSN",
  "sentilink_dob_completion_dob",
  "experian_business_match_reliability_code",
  "dnb_business_match",
  "dnb_bm_l1_confidence_score",
  "dnb_bm_l1_primary_name",
  "dnb_bm_l1_street_number",
  "dnb_bm_l1_street_name",
  "dnb_bm_l1_city",
  "dnb_bm_l1_state",
  "dnb_bm_l1_postal_code",
  "dnb_bm_l1_phone",
  "clear_id_confirm_business_business_name_match_score",
  "clear_id_confirm_business_city_match_score",
  "clear_id_confirm_business_country_match_score",
  "clear_id_confirm_business_duns_number_match_score",
  "clear_id_confirm_business_officer_agent_first_name_match_score",
  "clear_id_confirm_business_officer_agent_last_name_match_score",
  "clear_id_confirm_business_phone_number_match_score",
  "clear_id_confirm_business_state_match_score",
  "clear_id_confirm_business_street_match_score",
  "clear_id_confirm_business_total_match_score",
  "clear_id_confirm_business_additional_phone_number",
  "clear_id_confirm_business_business_address_is_used_as_residental_address",
  "clear_id_confirm_business_ofac_listing",
  "clear_id_confirm_business_is_another_businesses_linked_to_the_business_address",
  "clear_id_confirm_business_other_business_linked_to_the_same_ein",
  "clear_id_confirm_business_other_listings_linked_to_business_phone_number",
  "clear_id_confirm_business_po_box_listed_as_address",
  "clear_id_confirm_person_birth_date_match_score",
  "clear_id_confirm_person_city_match_score",
  "clear_id_confirm_person_first_name_match_score",
  "clear_id_confirm_person_last_name_match_score",
  "clear_id_confirm_person_phone_number_match_score",
  "clear_id_confirm_person_ssn_match_score",
  "clear_id_confirm_person_state_match_score",
  "clear_id_confirm_person_street_name_match_score",
  "clear_id_confirm_person_total_match_score",
  "clear_id_confirm_person_zipcode_match_score",
  "clear_id_confirm_person_additional_phone_numbers",
  "clear_id_confirm_person_death_indicator",
  "clear_id_confirm_person_multiple_ssns",
  "clear_id_confirm_person_ofac_listing",
  "clear_id_confirm_person_ssn_matches_multiple_individuals_indicator",
  "lexis_nexis_kyb_search_emp_id",
  "lexis_nexis_kyb_search_org_id",
  "lexis_nexis_kyb_search_pow_id",
  "lexis_nexis_kyb_search_prox_id",
  "lexis_nexis_kyb_search_sele_id",
  "lexis_nexis_kyb_search_ult_id"
] as const;

const BUSINESS_CREDIT_SERVICES = [
  "experian_fsr",
  "experian_intelliscore",
  "experian_trades",
  "experian_gdn_risk_check",
  "experian_gdn_small_report",
  "experian_gdn_extended_report",
  "experian_gdn_canadian_profile_report",
  "dnb_fi_l2",
  "dnb_fi_l3",
  "dnb_fi_l4",
  "dnb_pi_l3",
  "dnb_ci_l2",
  "dnb_dti_l1",
  "dnb"
] as const;

const BUSINESS_CREDIT_ATTRIBUTES = [
  "experian_fsr_score",
  "experian_fsr_percentile_ranking",
  "experian_fsr_risk_class",
  "experian_fsr_disputes",
  "experian_fsr_recommended_credit_limit",
  "experian_intelliscore_score",
  "experian_intelliscore_percentile_ranking",
  "experian_intelliscore_risk_class",
  "experian_intelliscore_disputes",
  "experian_intelliscore_recommended_credit_limit",
  "experian_trades_current_debt",
  "experian_trades_single_high_credit",
  "experian_trades_highest_debt_in_six_months",
  "experian_trades_monthly_average_debt",
  "experian_trades_all_tradeline_balance",
  "experian_trades_highest_debt_in_five_quarters",
  "experian_trades_current_account_balance",
  "experian_trades_median_credit_amount_extended",
  "experian_trades_account_balance",
  "experian_trades_recent_high_credit",
  "experian_trades_current_percentage",
  "experian_trades_customer_dispute_indicator",
  "experian_trades_monthly_trends_current_percentage",
  "experian_trades_monthly_trends_account_balance",
  "experian_trades_tradelines_current_debt",
  "experian_trades_tradelines_current_percentage",
  "experian_trades_tradelines_total_account_balance",
  "experian_trades_tradelines_total_high_credit_amount",
  "experian_trades_combined_tradelines_current_debt",
  "experian_trades_combined_tradelines_current_percentage",
  "experian_trades_combined_tradelines_total_account_balance",
  "experian_trades_combined_tradelines_total_high_credit_amount",
  "experian_trades_additional_tradelines_current_debt",
  "experian_trades_additional_tradelines_current_percentage",
  "experian_trades_additional_tradelines_total_account_balance",
  "experian_trades_additional_tradelines_total_high_credit_amount",
  "experian_trades_newly_reported_tradelines_current_debt",
  "experian_trades_newly_reported_tradelines_current_percentage",
  "experian_trades_newly_reported_tradelines_total_account_balance",
  "experian_trades_newly_reported_tradelines_total_high_credit_amount",
  "experian_trades_continuously_reported_tradelines_current_debt",
  "experian_trades_continuously_reported_tradelines_current_percentage",
  "experian_trades_continuously_reported_tradelines_high_credit_amount",
  "experian_trades_continuously_reported_tradelines_total_account_balance",
  "experian_gdn_risk_check_report_up_to_date",
  "experian_gdn_risk_check_hard_negative_facts_existing",
  "experian_gdn_risk_check_credit_rating",
  "experian_gdn_risk_check_credit_limit",
  "experian_gdn_risk_check_company_turnovers",
  "experian_gdn_small_report_credit_rating",
  "experian_gdn_small_report_credit_limit",
  "experian_gdn_small_report_company_turnovers",
  "experian_gdn_small_report_hard_negative_facts_existing",
  "experian_gdn_extended_report_credit_limit",
  "experian_gdn_extended_report_company_turnovers",
  "experian_gdn_extended_report_hard_negative_facts_existing",
  "experian_gdn_canadian_profile_report_payment_index",
  "experian_gdn_canadian_profile_report_credit_score",
  "experian_gdn_canadian_profile_report_risk_class",
  "experian_gdn_canadian_profile_report_credit_limit_90_day",
  "experian_gdn_canadian_profile_report_credit_limit_13_month",
  "experian_gdn_canadian_profile_report_credit_limit_all_lines",
  "experian_gdn_canadian_profile_report_total_past_due",
  "dnb_fi_l2_delinquency_class_score",
  "dnb_fi_l2_delinquency_national_percentile",
  "dnb_fi_l2_failure_class_score",
  "dnb_fi_l2_failure_national_percentile",
  "dnb_fi_l2_standard_rating_risk_segment",
  "dnb_fi_l2_lay_off_class_score",
  "dnb_fi_l2_emerging_market_mediation_class_score",
  "dnb_fi_l3_delinquency_raw_score",
  "dnb_fi_l3_delinquency_class_score",
  "dnb_fi_l3_delinquency_national_percentile",
  "dnb_fi_l3_delinquency_risk_incidence_percentage",
  "dnb_fi_l3_failure_raw_score",
  "dnb_fi_l3_failure_class_score",
  "dnb_fi_l3_failure_national_percentile",
  "dnb_fi_l3_failure_risk_incidence_percentage",
  "dnb_fi_l3_standard_rating_risk_segment",
  "dnb_fi_l3_lay_off_class_score",
  "dnb_fi_l3_emerging_market_mediation_class_score",
  "dnb_fi_l4_credit_limit_recommendation",
  "dnb_fi_l4_delinquency_raw_score",
  "dnb_fi_l4_delinquency_class_score",
  "dnb_fi_l4_delinquency_national_percentile",
  "dnb_fi_l4_delinquency_risk_incidence_percentage",
  "dnb_fi_l4_failure_raw_score",
  "dnb_fi_l4_failure_class_score",
  "dnb_fi_l4_failure_national_percentile",
  "dnb_fi_l4_failure_risk_incidence_percentage",
  "dnb_fi_l4_viability_class_score",
  "dnb_fi_l4_viability_score_bad_rate",
  "dnb_fi_l4_viability_score_class_score_incidence_percentage",
  "dnb_fi_l4_viability_score_overall_bad_rate",
  "dnb_fi_l4_viability_score_risk_level",
  "dnb_fi_l4_portfolio_comparison_score_class_score",
  "dnb_fi_l4_portfolio_comparison_score_bad_rate",
  "dnb_fi_l4_portfolio_comparison_score_class_score_incidence_percentage",
  "dnb_fi_l4_portfolio_comparison_score_class_score_segment_bad_rate",
  "dnb_fi_l4_portfolio_comparison_score_risk_level",
  "dnb_pi_l3_paydex_score",
  "dnb_ci_l2_number_of_employees",
  "dnb_dti_l1_three_months_paydex_score",
  "dnb_dti_l1_twelve_months_paydex_score",
  "dnb_unexpected_error"
] as const;

const LIENS_SERVICES = ["experian_liens"] as const;

const LIENS_ATTRIBUTES = [
  "experian_liens",
  "lexis_nexis_kyb_report_number_of_liens_filed"
] as const;

const UCCS_SERVICES = [
  "lexis_nexis_ucc_filing_search",
  "experian_uccs"
] as const;

const UCCS_ATTRIBUTES = [
  "experian_uccs",
  "lexis_nexis_ucc_filing_search_number_of_uccs_filed"
] as const;

const JUDGEMENT_SERVICES = [
  "experian_judgments",
  "experian",
  "lexis_nexis_kyb_report"
] as const;

const JUDGEMENTS_ATTRIBUTES = [
  "experian_judgments",
  "lexis_nexis_kyb_report_number_of_judgments_filed",
  "experian_unexpected_error"
] as const;

const FRAUD_SERVICES = ["socure_fraud", "sentilink"] as const;

const FRAUD_ATTRIBUTES = [
  "sentilink_person_match",
  "sentilink_unexpected_error",
  "sentilink_abuse",
  "sentilink_first_party_synthetic",
  "sentilink_third_party_synthetic",
  "sentilink_id_theft",
  "socure_person_match",
  "socure_kyc_unexpected_error",
  "socure_fraud_address_risk",
  "socure_fraud_email_risk",
  "socure_fraud_name_address_correlation",
  "socure_fraud_name_email_correlation",
  "socure_fraud_name_phone_correlation",
  "socure_fraud_phone_risk",
  "socure_fraud_synthetic"
] as const;

const KYC_SERVICES = ["ekata", "socure_kyc"] as const;
const KYC_ATTRIBUTES = [
  "ekata_person_match",
  "ekata_unexpected_error",
  "ekata_account_opening",
  "ekata_address_risk",
  "ekata_transaction_risk",
  "ekata_phone_risk",
  "socure_kyc_city",
  "socure_kyc_dob",
  "socure_kyc_first_name",
  "socure_kyc_mobile",
  "socure_kyc_ssn",
  "socure_kyc_state",
  "socure_kyc_street_address",
  "socure_kyc_surname",
  "socure_kyc_zip"
] as const;

const KYB_SERVICES = ["middesk", "lexis_nexis_kyb_search"] as const;
const KYB_ATTRIBUTES = [
  "middesk_business_match",
  "middesk_unexpected_error",
  "middesk_address_deliverability",
  "middesk_address_property_type",
  "middesk_address_verification",
  "middesk_bankruptcies",
  "middesk_name",
  "middesk_person_verification",
  "middesk_sos_active",
  "middesk_sos_match",
  "middesk_tin",
  "middesk_watchlist",
  "middesk_business_start_date",
  "middesk_entity_type",
  "lexis_nexis_kyb_search_matching_ein_number"
] as const;

export const PERSONAL_CREDIT_SERVICES = [
  "experian_bop_blended_prequalification",
  "experian_bop_commercial_lending_to_a_sole_prop",
  "experian_bop_commercial_lending_with_a_pg",
  "experian_bop_commercial_insurance",
  "experian_bop_merchant_cash_advance",
  "experian_bop_merchant_acquisition",
  "experian_bop_commercial_factoring",
  "experian_bop_blended_account_review",
  "experian_bop_commercial_collections"
] as const;
const PERSONAL_CREDIT_ATTRIBUTES = [
  "experian_bop_blended_prequalification_risk_model_score",
  "experian_bop_blended_prequalification_past_due_amount",
  "experian_bop_blended_prequalification_derogatory_counter",
  "experian_bop_blended_prequalification_revolving_balance",
  "experian_bop_blended_prequalification_real_estate_balance",
  "experian_bop_blended_prequalification_installment_balance",
  "experian_bop_blended_prequalification_now_delinquent_derog",
  "experian_bop_blended_prequalification_was_delinquent_derog",
  "experian_bop_blended_prequalification_revolving_available_percent",
  "experian_bop_blended_prequalification_inquiries_during_last_6_months",
  "experian_bop_commercial_lending_to_a_sole_prop_risk_model_score",
  "experian_bop_commercial_lending_to_a_sole_prop_past_due_amount",
  "experian_bop_commercial_lending_to_a_sole_prop_derogatory_counter",
  "experian_bop_commercial_lending_to_a_sole_prop_revolving_balance",
  "experian_bop_commercial_lending_to_a_sole_prop_real_estate_balance",
  "experian_bop_commercial_lending_to_a_sole_prop_installment_balance",
  "experian_bop_commercial_lending_to_a_sole_prop_now_delinquent_derog",
  "experian_bop_commercial_lending_to_a_sole_prop_was_delinquent_derog",
  "experian_bop_commercial_lending_to_a_sole_prop_revolving_available_percent",
  "experian_bop_commercial_lending_to_a_sole_prop_inquiries_during_last_6_months",
  "experian_bop_commercial_lending_with_a_pg_risk_model_score",
  "experian_bop_commercial_lending_with_a_pg_past_due_amount",
  "experian_bop_commercial_lending_with_a_pg_derogatory_counter",
  "experian_bop_commercial_lending_with_a_pg_revolving_balance",
  "experian_bop_commercial_lending_with_a_pg_real_estate_balance",
  "experian_bop_commercial_lending_with_a_pg_installment_balance",
  "experian_bop_commercial_lending_with_a_pg_now_delinquent_derog",
  "experian_bop_commercial_lending_with_a_pg_was_delinquent_derog",
  "experian_bop_commercial_lending_with_a_pg_revolving_available_percent",
  "experian_bop_commercial_lending_with_a_pg_inquiries_during_last_6_months",
  "experian_bop_commercial_insurance_risk_model_score",
  "experian_bop_commercial_insurance_past_due_amount",
  "experian_bop_commercial_insurance_derogatory_counter",
  "experian_bop_commercial_insurance_revolving_balance",
  "experian_bop_commercial_insurance_real_estate_balance",
  "experian_bop_commercial_insurance_installment_balance",
  "experian_bop_commercial_insurance_now_delinquent_derog",
  "experian_bop_commercial_insurance_was_delinquent_derog",
  "experian_bop_commercial_insurance_revolving_available_percent",
  "experian_bop_commercial_insurance_inquiries_during_last_6_months",
  "experian_bop_merchant_cash_advance_risk_model_score",
  "experian_bop_merchant_cash_advance_past_due_amount",
  "experian_bop_merchant_cash_advance_derogatory_counter",
  "experian_bop_merchant_cash_advance_revolving_balance",
  "experian_bop_merchant_cash_advance_real_estate_balance",
  "experian_bop_merchant_cash_advance_installment_balance",
  "experian_bop_merchant_cash_advance_now_delinquent_derog",
  "experian_bop_merchant_cash_advance_was_delinquent_derog",
  "experian_bop_merchant_cash_advance_revolving_available_percent",
  "experian_bop_merchant_cash_advance_inquiries_during_last_6_months",
  "experian_bop_merchant_acquisition_risk_model_score",
  "experian_bop_merchant_acquisition_past_due_amount",
  "experian_bop_merchant_acquisition_derogatory_counter",
  "experian_bop_merchant_acquisition_revolving_balance",
  "experian_bop_merchant_acquisition_real_estate_balance",
  "experian_bop_merchant_acquisition_installment_balance",
  "experian_bop_merchant_acquisition_now_delinquent_derog",
  "experian_bop_merchant_acquisition_was_delinquent_derog",
  "experian_bop_merchant_acquisition_revolving_available_percent",
  "experian_bop_merchant_acquisition_inquiries_during_last_6_months",
  "experian_bop_commercial_factoring_risk_model_score",
  "experian_bop_commercial_factoring_past_due_amount",
  "experian_bop_commercial_factoring_derogatory_counter",
  "experian_bop_commercial_factoring_revolving_balance",
  "experian_bop_commercial_factoring_real_estate_balance",
  "experian_bop_commercial_factoring_installment_balance",
  "experian_bop_commercial_factoring_now_delinquent_derog",
  "experian_bop_commercial_factoring_was_delinquent_derog",
  "experian_bop_commercial_factoring_revolving_available_percent",
  "experian_bop_commercial_factoring_inquiries_during_last_6_months",
  "experian_bop_blended_account_review_risk_model_score",
  "experian_bop_blended_account_review_past_due_amount",
  "experian_bop_blended_account_review_derogatory_counter",
  "experian_bop_blended_account_review_revolving_balance",
  "experian_bop_blended_account_review_real_estate_balance",
  "experian_bop_blended_account_review_installment_balance",
  "experian_bop_blended_account_review_now_delinquent_derog",
  "experian_bop_blended_account_review_was_delinquent_derog",
  "experian_bop_blended_account_review_revolving_available_percent",
  "experian_bop_blended_account_review_inquiries_during_last_6_months",
  "experian_bop_commercial_collections_risk_model_score",
  "experian_bop_commercial_collections_past_due_amount",
  "experian_bop_commercial_collections_derogatory_counter",
  "experian_bop_commercial_collections_revolving_balance",
  "experian_bop_commercial_collections_real_estate_balance",
  "experian_bop_commercial_collections_installment_balance",
  "experian_bop_commercial_collections_now_delinquent_derog",
  "experian_bop_commercial_collections_was_delinquent_derog",
  "experian_bop_commercial_collections_revolving_available_percent",
  "experian_bop_commercial_collections_inquiries_during_last_6_months"
] as const;

const DOCUMENT_VERIFICATION_SERVICES = ["persona", "inscribe"] as const;

const DOCUMENT_VERIFICATION_ATTRIBUTES = [
  "persona_has_document_verifications",
  "persona_unexpected_error",
  "persona_dl",
  "persona_pp",
  "persona_id",
  "inscribe_has_document_verifications",
  "inscribe_unexpected_error",
  "inscribe_fraud",
  "inscribe_trust_score",
  "inscribe_verification"
] as const;

const ACCOUNTING_DATA_SERVICES = ["codat"] as const;

const ACCOUNTING_DATA_ATTRIBUTES = [
  "codat_connection",
  "codat_balance_sheets_assets",
  "codat_balance_sheets_equity",
  "codat_balance_sheets_liabilities",
  "codat_profit_and_loss_net_operating_profit",
  "codat_profit_and_loss_net_profit",
  "codat_profit_and_loss_net_other_income"
] as const;

export const UNDERWRITING_ATTRIBUTES_AND_SERVICES_BY_SECTION = {
  "Cash Flow Analysis": [...CFA_SERVICES, ...CFA_ATTRIBUTES],
  "Bank Data": [...BANK_DATA_SERVICES, ...BANK_DATA_ATTRIBUTES],
  Bankruptcies: [...BANKRUPTCIES_SERVICES, ...BANKRUPTCIES_ATTRIBUTES],
  Match: [...MATCH_SERVICES, ...MATCH_ATTRIBUTES],
  "Business Credit": [
    ...BUSINESS_CREDIT_SERVICES,
    ...BUSINESS_CREDIT_ATTRIBUTES
  ],
  Liens: [...LIENS_SERVICES, ...LIENS_ATTRIBUTES],
  Judgments: [...JUDGEMENT_SERVICES, ...JUDGEMENTS_ATTRIBUTES],
  UCCs: [...UCCS_SERVICES, ...UCCS_ATTRIBUTES],
  Fraud: [...FRAUD_SERVICES, ...FRAUD_ATTRIBUTES],
  KYB: [...KYB_SERVICES, ...KYB_ATTRIBUTES],
  KYC: [...KYC_SERVICES, ...KYC_ATTRIBUTES],
  "Personal Credit": [
    ...PERSONAL_CREDIT_SERVICES,
    ...PERSONAL_CREDIT_ATTRIBUTES
  ],
  "Document Verification": [
    ...DOCUMENT_VERIFICATION_SERVICES,
    ...DOCUMENT_VERIFICATION_ATTRIBUTES
  ],
  "Accounting Data": [
    ...ACCOUNTING_DATA_SERVICES,
    ...ACCOUNTING_DATA_ATTRIBUTES
  ]
} as const;

export const TRANSUNION_CONSUMER_SCORE_MODELS = [
  "fico4",
  "fico8",
  "fico9",
  "fico10",
  "fico_bankruptcy2",
  "vantage1",
  "vantage2",
  "vantage3",
  "vantage3_0",
  "vantage4",
  "bankers_hg_score_1_hit_indv",
  "truvi_trend_new_acct_scor_nhit_indv",
  "truvi_trended_pl_score_hit_indv"
];

export const TRANSUNION_CREDIT_ALERT_MODELS = [
  "truvia_id_mismatch_alert_indv0m",
  "truvi_ofac_name_screen_clea",
  "truva_address_mismatch_indv0m"
];

export const TRANSUNION_CREDIT_REPORT_MODELS = [
  "truvi_credit_report_hit_indv_cpu_stdb_frgn",
  "truvi_credit_summary_indv",
  "truvi_mla_search_clea",
  "credit_vision_acute_relief_attributes",
  "credit_vision_banker_health_care_derogatory_criteria"
];

export const TRANSUNION_TRUVISION_TRENDED_MODELS = [
  "tru_vision_trended_algorithms_excess_payment_batch",
  "tru_vision_trended_algorithms_payment_ratio_batch",
  "tru_vision_trended_algorithms_utilization_batch",
  "tru_vision_trended_bankcard_spend_algorithms",
  "tru_vision_trended_historical_status_algorithms",
  "tru_vision_trended_balance_magnitude_algorithms",
  "tru_vision_trended_trade_to_consumer_algorithms",
  "tru_vision_trended_aggregate_view_algorithms_with_payment_amount",
  "tru_vision_trended_magnitude_algorithms"
];

export const TRANSUNION_OTHER_MODELS = [
  "cv_link_2_1_attributes_tru_vision_alternative_data_attributes",
  "custom_model_cvlink_f2_0",
  "tru_vision_premium_characteristics_v2",
  "tru_vision_phone_number_attributes"
];
