export enum OfferSaveMode {
  SAVE = "save",
  UPDATE = "update"
}

export enum OfferProductType {
  ARLOC = "arloc",
  EQUIPMENT_FINANCIN = "equipment_financing",
  LINE_OF_CREDIT = "line_of_credit",
  RECEIVABLES_PURCHASE = "receivables_purchase",
  TERM_LOAN = "term_loan"
}

export enum FieldType {
  DEFAULT = "default",
  CURRENCY = "currency",
  DATE = "date",
  INTEGER = "integer",
  FLOAT = "float",
  PERCENT = "percentage",
  OPTIONS = "options",
  TEXTAREA = "text",
  STRING = "string",
  URL = "url"
}

export enum FieldSection {
  OFFER = "offer",
  CLOSING = "closing"
}
